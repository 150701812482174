import { useEffect } from 'react';

const useScript = ({src, ...scriptData}) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = src
    script.setAttribute('async', true);

    Object.keys(scriptData).forEach((key, index) => {
      script.setAttribute(key, scriptData[key]);
    });

    document.body.appendChild(script);

    script.addEventListener('load', (event)=>{
      window.document.dispatchEvent(new Event("DOMContentLoaded", {
        bubbles: true,
        cancelable: true
        }));
      }
    )
    return () => {
      document.body.removeChild(script);
    }
  }, [src, scriptData]);
};

export default useScript;