import React, { useEffect, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { toast } from 'react-toastify'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import styled from 'styled-components'
// import { Button } from 'reactstrap'
import { connect } from 'react-redux'

import { handlerErrors, floorTypeSubtotals } from 'utils'
import { axiosClient } from 'store'

import { connectConfirmation } from 'components/confirm'
import SignatureForm from '../../components/forms/SignatureForm'
import CloneForm from '../../components/forms/CloneForm'
import EmailForm from '../../components/forms/EmailForm'
import FileDownload from '../components/FileDownload'

import Elements from '../Proposal2/Elements'
import { calculateProposalTotals } from '../Proposal2/util'
import { RENDER_ELEMENT } from './rendererElements'
import { ClapSpinner } from 'components/Loader/ClapSpinner'
import { useGetProposalQuery } from 'store/apis'
import SyncLocationDropdown from 'components/lib/Button/SyncLocationDropdown'
import PulseButton from 'components/lib/Button/PulseButton'
import { BusyButton, SweptButton } from 'components/lib/Button'
import { useSyncLocation } from 'hooks/useSyncLocation'
import { useSelector } from 'react-redux'
import SweptConnectForm from 'screens/Dashboard/components/forms/SweptConnectForm'
// import { useSelector, useDispatch } from 'react-redux'
// import { getProposals } from '../../../../store/slices/proposals'


const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const ScrollWrapper = styled.div`
  max-height: calc(100vh - 110px);
  overflow-y: auto;
  overflow-x: hidden;
  margin: 20px 0;
`

const ProposalContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  max-width: 768px;
`

const ElementPreview = styled.div`
  position: relative;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #e7e7e7;
  border-radius: 5px;
  height: fit-content;
  margin: 20px 20px;
  padding: 20px;
  width: fit-content;
`

const StyledButton = styled(Button)`
  margin: 5px 0;
`

const CompletedProposal = ({ match, history, confirm }) => {
  // const [proposal, setProposal] = useState(null)
  const { proposalId } = match.params
  const [showCloneModal, setShowCloneModal] = useState(false)
  const [signatureFormOpen, setSignatureFormOpen] = useState(false)
  const [showEmailLinkForm, setShowEmailLinkForm] = useState(false)
  const [emailFormBusy, setEmailFormBusy] = useState(false)

  const { data: proposal, isError, isLoading, refetch } = useGetProposalQuery(proposalId)

  const { currentEmployment } = useSelector((state) => state.user)

  const {  
    pulseAccountId,
    setPulseAccountId,
    pulseConnectBusy,
    setPulseConnectBusy,
    pulseLocationExists,
    setPulseLocationExists,
    sweptLocationExists,
    setSweptLocationExists,
    showVerifyModal,
    setShowVerifyModal,
    showPulseConnect,
    setShowPulseConnect,
    detailAcountPulse,
    setDetailAcountPulse,
    
    showSweptConnect,
    setShowSweptConnect,
    sweptConnectBusy,
    setSweptConnectBusy,

    sendPulseLocation,
    sendSweptLocation,
    onPulseConnect,
    onSweptConnect,
  } = useSyncLocation(proposal)

  // const { proposal } = useSelector((state) => state.completedProposal)
  // // const { counter } = useSelector((state) => state.completedProposal)
  // const dispatch = useDispatch()


  // useEffect(() => {

  //   if (proposal === null) {
  //     dispatch(getProposals({ proposalId }))
  //   }

  //   // const { proposalId } = match.params
  //   // dispatch(getProposals({ proposalId }))

  //   // const fetchProposal = async () => {
  //   //   const result = await axiosClient.get(
  //   //     `/api/proposals/${match.params.proposalId}/proposalPortal`
  //   //   )
  //   //   if (result.error) {
  //   //     let message = handlerErrors(
  //   //       result.error,
  //   //       'Proposal could not be found.'
  //   //     )
  //   //     toast(message)
  //   //     return
  //   //   }

  //   //   const proposalRes = result?.data
  //   //   setProposal(proposalRes)
  //   // }

  //   // fetchProposal()
  // }, [])
  const handlesendPulseLocation = async () => {
    const response = await sendPulseLocation()
    // refetch()
    console.log(response)
  }

  const handlePulseConnect = async () => {
    await onPulseConnect()
  }
  const handleSweptConnect = async (values) => {
    await onSweptConnect(values)
  }

  const integrationOptions = [
    {
      component: (
        <PulseButton
          className={`cta btn`}
          onClick={() => handlesendPulseLocation()}
          busy={pulseConnectBusy}
          isUpdate={pulseLocationExists}
          color={'light'}
        />
      )
    },
    {
      component: (
        <SweptButton
          onClick={sendSweptLocation}
          isUpdate={sweptLocationExists}
          color={'light'}
        />
      )
    }
  ]

  const _cloneProposal = async values => {
    try {
      const res = await axiosClient.post(
        `/api/proposals/clone/${proposal?._id}`,
        values
      )

      await setShowCloneModal(false)

      history.push(`/dashboard/edit-proposal/${res?.data?._id}`)
      toast('Proposal successfully cloned.')
    } catch (ex) {
      console.error('Proposal could not be cloned..', ex)
    }
  }

  const _emailLink = async (values, event) => {
    setEmailFormBusy(true)

    let emailList = []

    if (values.emailContact) {
      emailList.push(proposal.client.contact.email)
    }

    if (values.otherEmails) {
      emailList = emailList.concat(values.otherEmails.split(','))
    }

    emailList = emailList
      .map(email => (email = email.trim()))
      .filter(email => email.length > 0)
    values.emailList = emailList
    values.clientId = proposal.client._id
    values.proposalId = proposal._id

    await axiosClient.post(
      'api/proposals/emailPortalLink',
      {
        emailList: values.emailList,
        clientId: values.clientId,
        proposalId: values.proposalId,
        message: values.message
      },
      {
        timeout: 30000
      }
    )

    setEmailFormBusy(false)
    setShowEmailLinkForm(false)

    toast('Proposal has been sent.', {
      className: 'toast-success'
    })
  }

  const _updateProposalStats = async () => {
    let data = {
      revenueData: {}
    }

    data.revenueData = await calculateProposalTotals(proposal?.data?.elements)
    let areas = []
    proposal?.data?.elements?.forEach(element => {
      if (element.type === 'areas') {
        areas = areas.concat(element.data.areas)
      }
    })
    if (!areas.length) {
      areas = proposal?.walkthrough?.areas
    }
    if (!areas.length) {
      areas = proposal?.location?.areas
    }
    if (areas.length) {
      const floorSubtotals = await floorTypeSubtotals(areas)
      data.revenueData.totalSqFt = floorSubtotals.total
    }

    try {
      await axiosClient.patch(`/api/proposals/${proposal._id}`, data)
    } catch (ex) {
      console.error('Proposal stats could not be updated..', ex)
    }
  }

  const _archiveProposal = async () => {
    if (!proposal) {
      return null
    }

    let data = {
      archived: true
    }

    try {
      await axiosClient.patch(`/api/proposals/${proposal._id}`, data)

      history.push('/dashboard/proposals')
    } catch (ex) {
      console.error('Proposal could not be archived..', ex)
    }
  }

  const _downloadFile = async () => {
    let str = ReactDOMServer.renderToString(
      proposal?.data?.elements?.map((element, index) => {
        let master
        Elements.find(m => m.type === element.type)
        if (master && master.print) {
          // Don't know why but this needs to replace closing tags first then opening tags for the markdown text to remain intact.
          master = master
            .print(element)
            .replace(/<\/.*>/g, '')
            .replace(/<.*>/g, '')
        } else {
          master = _renderElement(element, index, proposal)
        }
        return master
      })
    )

    const result = await axiosClient({
      url: 'api/proposals/pdf',
      method: 'post',
      data: {
        proposal: str,
        showPages: proposal?.data?.options?.showPageNumbers
      },
      responseType: 'blob',
      timeout: 100000
    })

    const fileName = `Proposal-${proposal.merchant ? proposal.merchant.name : ''
      }-${proposal.client ? proposal.client.name : ''}.pdf`

    if (!result.error) {
      FileDownload(result.data, fileName)
    } else {
      console.error(result.error)
    }
  }

  const _submitSignature = async signature => {
    const data = {
      signature
    }

    const result = await axiosClient.patch(
      `/api/proposals/${proposal._id}/merchantSigned`,
      data
    )

    if (result.error) {
      let message = handlerErrors(
        result.error,
        'There was an error submitting signature.'
      )
      toast(message)
      return
    }
    toast('Proposal signed.')
    await setSignatureFormOpen(false)
    await setProposal(result.data)
    setShowEmailLinkForm(true)
    _updateProposalStats()
  }

  // const _renderElementContent = (element, index, proposal) => {

  //   // const elementRender = Elements.find(e => {

  //   //   if (e.type === element.type && element.type === "letterhead") {
  //   //     return e.render(element, proposal)
  //   //   }

  //   // }) || <div />

  //   // if (elementRender.type === "letterhead") {
  //   //   console.log(element)
  //   //   let objectConfig = elementRender.render(element, proposal)

  //   //   return objectConfig
  //   // }
  //   // let renderEjc

  //   // console.log(proposal)
  //   return (
  //     (Elements.find(e => e.type === element.type) &&
  //       Elements.find(e => e.type === element.type).render({
  //         element,
  //         proposal
  //       })) || <div />
  //   )
  // }

  const _renderElement = (element, index, proposal, totalCount) => {

    const types = Object.keys(RENDER_ELEMENT)

    return (
      <ElementPreview key={`proposal-element-preview-${index}`}>
        {types.includes(element.type)
          ? RENDER_ELEMENT[element.type](element, proposal)
          : null
        }
      </ElementPreview>
    )
  }

  const toggleSignatureForm = () => {
    setSignatureFormOpen(!signatureFormOpen)
  }

  const alreadySigned = proposal?.signatureData?.merchantSignature

  return (
    <PageContainer>
      {showEmailLinkForm ? (
        <Modal isOpen={showEmailLinkForm} style={{ paddingTop: 50 }}>
          <ModalHeader
            style={{ justifyContent: 'center' }}
            toggle={() => setShowEmailLinkForm(false)}
          >
            {'Send Proposal'}
          </ModalHeader>
          <ModalBody>
            <EmailForm
              onSubmit={_emailLink}
              busy={emailFormBusy}
              hideAttachments={true}
              hideSubject={true}
              hideCC={true}
              clientContact={
                proposal?.client?.contact?.email
                  ? proposal.client.contact
                  : null
              }
            />
          </ModalBody>
        </Modal>
      ) : null}
      {proposal && proposal._id && (
        <CloneForm
          open={showCloneModal}
          toggle={() => setShowCloneModal(false)}
          header={`Clone Proposal ${proposal.code}`}
          onSubmit={_cloneProposal}
          initialValues={{
            proposal: proposal._id,
            client: proposal.client?._id,
            location: proposal.location?._id
          }}
          cloningMessage="Proposal will be cloned without signatures."
        />
      )}
      {signatureFormOpen ? (
        <SignatureForm
          isOpen={signatureFormOpen}
          toggle={toggleSignatureForm}
          onSubmitSignature={_submitSignature}
          proposalId={proposal?._id}
        />
      ) : null}
      {proposal != null ?
        <ScrollWrapper>
          <ProposalContainer className="proposal-new">

            {proposal.data.elements.map((item, index) => {

              return _renderElement(item, index, proposal)

            })}
          </ProposalContainer>
        </ScrollWrapper>
        :
        <ClapSpinner size={50} loading={true} marginTop={10} />
      }

      <ButtonContainer>
        {alreadySigned ? (
          <StyledButton
            onClick={() => setShowEmailLinkForm(true)}
            style={{ width: 150 }}
            color="primary"
          >
            Send Proposal
          </StyledButton>
        ) : (
          <StyledButton
            onClick={toggleSignatureForm}
            style={{ width: 150 }}
            color="primary"
          >
            Sign
          </StyledButton>
        )}
        <StyledButton onClick={_downloadFile}>Download PDF</StyledButton>
        <StyledButton onClick={() => setShowCloneModal(true)}>
          Clone Proposal
        </StyledButton>
        <StyledButton
          color="danger"
          onClick={() =>
            confirm(
              'Confirm Archive',
              'Are you sure you want to archive this proposal?',
              _archiveProposal
            )
          }
        >
          Archive Proposal
        </StyledButton>
        <SyncLocationDropdown options={integrationOptions} />
      </ButtonContainer>
      
      <Modal isOpen={showSweptConnect} style={{ paddingTop: 50 }}>
        <ModalHeader
          style={{ justifyContent: 'center' }}
          toggle={() => setShowSweptConnect(false) }
        >
          {'Connect to Swept'}
        </ModalHeader>
        <ModalBody>
          <p>Connect to your Swept account to share locations from Route</p>
          <SweptConnectForm
            initialValues={{ username: currentEmployment.email }}
            onSubmit={ handleSweptConnect }
            busy={sweptConnectBusy}
          />
        </ModalBody>
      </Modal>
      
      <Modal isOpen={showPulseConnect} style={{ paddingTop: 50 }}>
        <ModalHeader
          style={{ justifyContent: 'center' }}
          toggle={() => setShowPulseConnect(false)}
        >
          {'Connect to Pulse'}
        </ModalHeader>
        <ModalBody>
          <p>Connect to your Pulse account to share proposals from Bid</p>
          <BusyButton
            className={`cta btn ${pulseAccountId ? 'disabled' : ''}`}
            size={'md'}
            onClick={() => handlePulseConnect()}
            color="primary"
            style={{ marginRight: 10 }}
            busy={pulseConnectBusy}
          >
            {pulseAccountId ? 'Connected to Pulse' : 'Connect Pulse Account'}
          </BusyButton>
        </ModalBody>
      </Modal>
    </PageContainer>
  )
}

export default connectConfirmation(CompletedProposal)
