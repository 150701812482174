import React from 'react'
import _ from 'lodash'

import { isImage } from 'utils/imageHelpers'
import floorTypeSubtotals from 'utils/floorTypeSubtotals'

export default ({ element, proposal }) => {
  const showHeading = element && element.data && element.data.showHeading
  const headingText =
    (element && element.data && element.data.headingText) || 'Areas'

  let areas

  if (element && element.data && element.data.areas) {
    areas = element.data.areas
  }

  if (!areas || !areas.length) {
    return <div>No areas found.</div>
  }

  const includeSquareFootage = element.data.includeSquareFootage
  const includeNotes = element.data.includeNotes
  const includeImages = element.data.includeImages
  const includeFixtures = element.data.includeFixtures
  const showFixtureAggregate = element.data.showFixtureAggregate
  const showSquareFootageTotals = element.data.showSquareFootageTotals

  const renderedAreas = areas.map((area, index) => {
    const areaDimensions =
      area.dimensions &&
      area.dimensions.set &&
      area.dimensions.set.map(dim => (
        <span className="dimension">
          {parseFloat(dim.width).toFixed(2)} ft. x{' '}
          {parseFloat(dim.depth).toFixed(2)}
        </span>
      ))
    let imageAttachments = area.attachments.filter(d => isImage(d.type))
    const renderPhotos =
      imageAttachments.length < 6
        ? (imageAttachments || []).map(image => (
            <img src={image.url} alt="area photos" className="area-image" />
          ))
        : imageAttachments
            .slice(0, 5)
            .map(image => (
              <img src={image.url} alt="area photos" className="area-image" />
            ))
    const morePhotos =
      imageAttachments.length > 5 ? (
        <span style={{ paddingLeft: 8 }}>
          + {imageAttachments.length - 5} more
        </span>
      ) : null

    const renderFixtures = area.fixtures.length ? (
      <table style={{ width: '100%' }}>
        <tbody>
          {area.fixtures.map((fixture, index) => (
            <tr key={`fixture-${index}`}>
              <td className="text-left">{fixture.type}</td>
              <td className="text-left" style={{ width: '20%' }}>
                {fixture.quantity}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : null

    const floorAreas = includeSquareFootage &&
      area.dimensions &&
      area.dimensions.set &&
      !!area.dimensions.set.length && (
        <div className="area-item">
          <span className="item-title">FLOOR AREA: </span>
          {areaDimensions}
        </div>
      )

    const floorType = area.floorType && (
      <div className="area-item">
        <span className="item-title">FLOOR TYPE: </span>
        {area.floorType}
      </div>
    )

    const notes = includeNotes && area.notes && (
      <div className="area-item">
        <span className="item-title">NOTES: </span>
        {area.notes}
      </div>
    )

    const photos = includeImages && area.attachments && (
      <div className="area-item">
        {renderPhotos}
        {morePhotos}
      </div>
    )

    const fixtures = includeFixtures && area.fixtures && !!area.fixtures.length && (
      <div className="area-item">
        <span className="item-title">FIXTURES:</span>
        {renderFixtures}
      </div>
    )

    return (
      <div key={`area-${index}`} className="area">
        <p className="area-name">
          {area.name}
          <span className="area-type">
            (<i style={{ marginRight: 2 }}>{area.type}</i>)
            {includeSquareFootage && area.squareFootage
              ? ' ' + parseFloat(area.squareFootage).toFixed(2) + ' SF'
              : ''}
          </span>
        </p>
        {floorAreas}
        {floorType}
        {notes}
        {fixtures}
        {photos}
      </div>
    )
  })

  let fixtureAggregate = null

  if (showFixtureAggregate) {
    let fixtures = []

    let fixturesGrouped = _.groupBy(
      _.flatten(_.map(areas, 'fixtures')),
      fixture => fixture.type.toLowerCase().trim()
    )

    let fixturesGroupedKeys = Object.keys(fixturesGrouped)
    fixturesGroupedKeys.forEach(key => {
      const quantity = fixturesGrouped[key].reduce(
        (accumulator, currentValue) =>
          accumulator + parseInt(currentValue.quantity, 10),
        0
      )
      fixtures.push({ key: _.capitalize(key), quantity })
    })

    fixtures = _.orderBy(fixtures, 'key', 'asc')

    if (fixtures.length) {
      let fixtureRows = fixtures.map((fixture, index) => {
        return (
          <tr key={`fixture-${index}`}>
            <td>{fixture.key}</td>
            <td style={{ width: '20%' }}>{fixture.quantity}</td>
          </tr>
        )
      })

      fixtureAggregate = (
        <div style={{ marginTop: 16 }}>
          <h5>Fixture Totals</h5>
          <table className="area-totals-table">
            <thead>
              <tr>
                <th>Fixture</th>
                <th style={{ width: '20%' }}>Quantity</th>
              </tr>
            </thead>
            <tbody>{fixtureRows}</tbody>
          </table>
        </div>
      )
    }
  }

  let sqFtTotals = null

  if (showSquareFootageTotals) {
    let totalSquareFootage = 0
    let floorSubtotals = {}

    const calcTotals = floorTypeSubtotals(areas)
    totalSquareFootage = calcTotals.total
    floorSubtotals = calcTotals.types

    totalSquareFootage = totalSquareFootage.toFixed(2)

    let subtotals = Object.keys(floorSubtotals).map((type, index) => (
      <tr key={`sq-ft-${index}`}>
        <td>{type}</td>
        <td style={{ width: '20%' }}>{floorSubtotals[type].toFixed(2)}</td>
      </tr>
    ))

    sqFtTotals = (
      <div style={{ marginTop: 16 }}>
        <h5>Square Footage Totals</h5>
        <table className="area-totals-table">
          <thead>
            <tr>
              <th>Floor Type</th>
              <th style={{ width: '20%' }}>Square Feet</th>
            </tr>
          </thead>
          <tbody>
            {subtotals}
            <tr>
              <td>
                <strong>Total</strong>
              </td>
              <td style={{ width: '20%' }}>
                <strong>{totalSquareFootage}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div style={{ paddingBottom: 20 }}>
      {showHeading && <h2 className="section-title">{headingText}</h2>}
      {renderedAreas}
      {fixtureAggregate}
      {sqFtTotals}
    </div>
  )
}
