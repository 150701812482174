
import { BID_MERCHANT_CREATE_MERCHANT } from "store/analyticsActions/types/merchantTypes"

import { fetchACL } from "./acl"

import { trackEvent } from "./clientHub"
import { BID_USER_LOGIN_USER } from "store/analyticsActions/types/userTypes"




export const useCompany = company => async dispatch => {
  await refreshToken(company._id)

  return dispatch({
    type: 'USE_COMPANY_SUCCESS',
    payload: {
      data: {
        company
      }
    }
  })
}

export const createToken = user => async dispatch => {
  const res = await dispatch({
    type: 'CREATE_TOKEN',
    payload: {
      request: {
        url: '/auth/createToken',
        method: 'post',
        data: { user, token: localStorage.getItem('AuthToken') }
      }
    }
  })

  const { payload: { data } } = res

  if (data.isNewMerchant) {
    trackEvent({ typeEvent: BID_MERCHANT_CREATE_MERCHANT })
  }

  trackEvent({ typeEvent: BID_USER_LOGIN_USER })

  if (!res.error) {
    await fetchACL(dispatch)

    return dispatch({
      type: 'USER_PROFILE',
      payload: {
        request: {
          url: '/api/users/me'
        }
      }
    })
  }
}

export const refreshToken = companyId => async dispatch => {
  const res = dispatch({
    type: 'REFRESH_TOKEN',
    payload: {
      request: {
        url: '/auth/refreshToken',
        method: 'post',
        data: {
          company: companyId
        }
      }
    }
  })

  if (!res.error) {
    await fetchACL(dispatch)

    return dispatch({
      type: 'USER_PROFILE',
      payload: {
        request: {
          url: '/api/users/me'
        }
      }
    })
  }
}

export const signUp = data => dispatch => {
  return dispatch({
    type: 'SIGN_UP',
    payload: {
      request: {
        url: '/api/companies/signup',
        method: 'post',
        data
      }
    }
  })
}
