import React, { useEffect, useRef, useMemo, useState } from 'react'
import { Col, Row, Card, CardTitle, CardSubtitle, CardBody, CardText, Alert, Input, Button, Spinner } from 'reactstrap'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'

import { AddArea } from './AddArea'
import { CrudTable } from './CrudTable'
import { fullAddress } from 'utils'
import { axiosClient } from 'store'

import { trackEvent } from 'api/clientHub'
import { USER_CREATE_INSPECTION_SUCCESS } from 'store/analyticsActions/types/inspectionsTypes'

import RouteRoomTypes from 'data/RoomTypes'


const styleContainer = {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

const styleColumnBody = {
    padding: 0

}

const tableContainerStyle = {
    border: '1px solid #000',
    overflow: 'hidden',
    padding: '8px',
    borderRadius: "10px"
}


const initialStateRequest = {
    scheduledAt: '',
    client: '',
    location: '',
    type: '',
    timezone: '',
    team: [],
    notes: '',
    areas: [],
    doFromRequest: true
}

const InspectionList = ({ initialInspectionValues, history }) => {
    const { client, location, team, notes, type, scheduledAt } = initialInspectionValues
    console.log('history from inspectionList', history)
    const [inputNotes, setInputNotes] = useState(notes)
    const [tableState, setTableState] = useState([])
    const [roomTypes, setRoomTypes] = useState([])

    const [dataToEdit, setDataToEdit] = useState(null)
    const [InspectionState, setInspectionState] = useState([])

    const [requestPayload, setRequestPayload] = useState(initialStateRequest)


    const [isLoading, setIsLoading] = useState(false)


    const [modalAreaOpen, setModalAreaOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const onDismiss = () => setVisible(false);

    const toggleAreaModal = () => setModalAreaOpen(!modalAreaOpen);

    const defaultRoomTypes = useMemo(() => {
        let values = Object.values(RouteRoomTypes)
        return values.map(value => value.label)
    }, [])

    useEffect(() => {
        setIsLoading(true)
        axiosClient.get(`/api/jobs/getAreasTasksFromProposal?clientId=${client._id}&locationId=${location._id}`)
            .then(response => {
                buildinitialState(response.data)
                setIsLoading(false)
            })
            .catch(error => {
                console.log(error)
                console.log("ingreso a realizar este cambio")
                setIsLoading(false)
                setRoomTypes(defaultRoomTypes)
            })
    }, [])

    useEffect(() => {
        if (requestPayload?.client
            && requestPayload?.location
            && requestPayload?.type
            && requestPayload?.scheduledAt
            && requestPayload?.team.length > 0
            && requestPayload?.areas.length > 0
        ) {
            setIsLoading(true)

            console.log(requestPayload)

            axiosClient.post('/api/jobs', requestPayload)
                .then(response => {
                    trackEvent({ typeEvent: USER_CREATE_INSPECTION_SUCCESS })
                    console.log(response)
                    setIsLoading(false)
                    toast.success('Inspection created successfully')
                    history.replace('/dashboard/inspections')
                })
                .catch(error => {
                    console.log(error)
                    setIsLoading(false)
                    toast.error('Error creating inspection')
                })
        }
    }, [requestPayload])

    const buildinitialState = (data) => {

        const { Areas } = data

        // console.log(Areas)
        //TODO: preguntar si quieren estos opciones para roomTypes o los que ya tenemos en la sistema
        let roomTypesResponse = Areas.map(area => area.name)

        let requestAreas = Areas.map(area => ({
            name: area.name,
            type: area.name,
            areasDetail: [],
            ignore: false,
            tasks: area.tasksNames
        }))


        let initialStateForRequest = Areas.map(area => ({
            type: area.name,
            areasDetail: [],
            ignore: false
        }))


        setInspectionState(initialStateForRequest)
        setTableState(requestAreas)
        setRoomTypes([...new Set([...roomTypesResponse, ...defaultRoomTypes])])
    }

    const _addArea = () => {
        setDataToEdit(null)
        toggleAreaModal()
    }
    const _editArea = (area) => {
        console.log({ area })
        setDataToEdit(area)
        toggleAreaModal()
    }

    const submitArea = (area, index) => {

        if (index >= 0) {
            updateData(area, index)
        } else {
            createData(area)
        }
        toggleAreaModal()
    }

    const createData = (newArea) => {
        let newTableState = [...tableState]
        newTableState.push(newArea)
        setTableState(newTableState)
    }

    const updateData = (updatedArea, index) => {
        let newTableState = [...tableState]
        console.log('updatedArea', updatedArea, index)
        newTableState[index] = updatedArea
        setTableState(newTableState)
    }

    const deleteData = (index) => {
        let newTableState = [...tableState]

        newTableState.splice(index, 1)
        setTableState(newTableState)
    }

    const handleDoneInspection = () => {


        let areasRequest = roomTypes.map(room => ({
            type: room,
            areasDetail: [],
            ignore: false
        }))

        if (tableState.length == 0) {
            setVisible(true)
            return
        } else {

            setVisible(false)
        }


        for (const item of tableState) {
            let areaDetailAux = {
                name: item.name,
                customTasks: item.tasks
            }

            areasRequest.find(ele => ele.type == item.type).areasDetail.push(areaDetailAux)
        }

        areasRequest = areasRequest.filter(ele => ele.areasDetail.length > 0)

        setRequestPayload({
            scheduledAt: moment(scheduledAt).format('YYYY-MM-DDTHH:mm:ssZ'),
            client: client.id,
            location: location.id,
            type: type,
            timezone: moment.tz.guess(),
            team: [team.id],
            notes: inputNotes,
            areas: areasRequest,
            doFromRequest: true
        })
    }


    console.log({ roomTypes })

    return (


        < Col xs="12" style={styleContainer} >
            {isLoading
                ?
                <Spinner color="primary" style={{ height: '3rem', width: '3rem' }}>
                    Loading...
                </Spinner>

                :

                <Card style={{ width: '75%' }}>
                    <CardBody>
                        <CardTitle tag="h5">
                            Inspection
                        </CardTitle>
                        <CardSubtitle className="mb-2 text-muted" tag="h6">
                            {client?.name} at {location.address && fullAddress(location.address)}
                        </CardSubtitle>
                    </CardBody>
                    <CardBody>
                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
                            <div>
                                <Col xs="12" style={{ display: 'flex', flexDirection: 'column', rowGap: '2rem' }}>
                                    <Row>
                                        <Col xs="12" style={styleColumnBody}>
                                            <CardText tag="h5">Inspector</CardText>
                                            <CardText>{team?.name?.full}</CardText>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" style={styleColumnBody}>
                                            <CardText tag="h5">Notes</CardText>
                                            <Input
                                                id="notesInspection"
                                                name="notes"
                                                placeholder="No notes"
                                                type="text"
                                                value={inputNotes}
                                                onChange={(e) => setInputNotes(e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" style={styleColumnBody}>
                                            <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                                                <CardText tag="h5">Areas</CardText>
                                                <Button color="link" onClick={_addArea}>
                                                    + Add Area
                                                </Button>
                                            </div>

                                            <div style={tableContainerStyle}>

                                                <CrudTable data={tableState} deleteData={deleteData} setDataToEdit={_editArea} />

                                            </div>
                                        </Col>

                                    </Row>
                                </Col>
                                {false &&
                                    <Col xs="2">
                                        <span>Action Button Section</span>
                                    </Col>

                                }

                            </div>
                            <Alert color="danger" isOpen={visible} toggle={onDismiss}>
                                Oops! You need to create at least 1 area to complete this action.
                            </Alert>
                            <div style={{ alignSelf: 'end' }}>

                                <Button color='primary' onClick={handleDoneInspection}>Done</Button>
                            </div>
                        </div>
                    </CardBody>

                </Card>
            }


            {modalAreaOpen && (
                <AddArea modalOpen={modalAreaOpen} toggleModal={toggleAreaModal} roomTypes={roomTypes} submitData={submitArea} dataToEdit={dataToEdit} setRoomTypes={setRoomTypes} />
            )}
            {/* <AddTasks modalOpen={modalTaskOpen} toggleModal={toggleTaskModal} /> */}


        </Col >




    )
}

export default InspectionList