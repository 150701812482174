import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  Table
} from 'reactstrap'

import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa'
import _ from 'lodash'

const showAlphaFeatures = process.env.REACT_APP_SHOW_ALPHA_FEATURES === '1'

const limitOptions = [10, 20, 50, 100]

class TableView extends Component {
  sorterChange = clickedIndex => {
    const { sortBy, sortAsc, sortIndex, setSort } = this.props
    if (sortIndex === clickedIndex) {
      if (sortBy) {
        let newSort = {
          sortBy,
          sortIndex,
          sortAsc: sortAsc ? 0 : 1
        }
        setSort(newSort)
      }
    } else {
      const selectedCol = this.props.columns[clickedIndex]

      if (selectedCol) {
        if (selectedCol.accessor || selectedCol.sortField) {
          let newSort = {
            sortBy: selectedCol.accessor || selectedCol.sortField,
            sortAsc: 0,
            sortIndex: clickedIndex
          }
          setSort(newSort)
        }
      }
    }
  }

  render() {
    const {
      columns,
      onRowClick,
      pagination,
      data,
      sortIndex,
      sortAsc
    } = this.props

    const renderedItems = data.map((item, index) => {
      return (
        <tr
          key={index}
          onClick={() => (onRowClick ? onRowClick(item) : null)}
          style={{ cursor: onRowClick ? 'pointer' : 'normal' }}
        >
          {columns.map((col, cindex) => {
            if (col.isAlphaFeature && !showAlphaFeatures) {
              return null
            } else if (col.render) {
              return (
                <td
                  className="align-middle"
                  key={cindex}
                  style={{
                    textAlign: col.textAlign || 'left',
                    width: col.width || 'auto'
                  }}
                >
                  {col.render(item, index)}
                </td>
              )
            } else if (col.accessor) {
              return (
                <td
                  className="align-middle"
                  key={cindex}
                  style={{
                    textAlign: col.textAlign || 'left',
                    width: col.width || 'auto'
                  }}
                >
                  {_.get(item, col.accessor)}
                </td>
              )
            } else {
              return <td />
            }
          })}
        </tr>
      )
    })

    const renderHeaders = columns.map((col, index) => {
      if (col.isAlphaFeature && !showAlphaFeatures) {
        return null
      } else {
        return (
          <th
            key={index}
            style={{
              color: 'white',
              cursor: 'pointer',
              textAlign: col.textAlign || 'left'
            }}
            onClick={() => col.sortable && this.sorterChange(index)}
          >
            {col.header}
            {col.sortable && (
              <span>
                {sortIndex !== index ? <FaSort color="grey" /> : null}
                {sortIndex === index && !sortAsc ? <FaSortUp /> : null}
                {sortIndex === index && sortAsc ? <FaSortDown /> : null}
              </span>
            )}
          </th>
        )
      }
    })

    let pages = []
    if (pagination) {
      const maxPageButtons = 3
      for (let i = 0; i < pagination.totalPages; ++i) {
        pages.push(
          <PaginationItem key={'page' + i} active={pagination.page === i + 1}>
            <PaginationLink onClick={() => this.props.onPageClick(i + 1)}>
              {i + 1}
            </PaginationLink>
          </PaginationItem>
        )
      }

      if (pages.length > maxPageButtons) {
        const firstPage = Math.max(
          1,
          pagination.page - Math.floor(maxPageButtons / 2)
        )
        const lastPage = firstPage + maxPageButtons
        pages = pages.slice(firstPage - 1, lastPage - 1)
      }
    }

    return (
      <>
        <Table
          striped
          responsive
          className="table-hover table-dark"
          style={this.props.style}
        >
          <thead>
            <tr>{renderHeaders}</tr>
          </thead>
          <tbody>{renderedItems}</tbody>
        </Table>
        {pagination && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div style={{ color: 'white' }}>
              {pagination.totalDocs} total items.
            </div>
            <div style={{ display: 'flex', color: 'white' }}>
              Results per page:
              <Pagination size="sm" style={{ marginLeft: 8 }}>
                {limitOptions.map((limit, index) => {
                  return (
                    <PaginationItem
                      key={'limitOption' + index}
                      active={limit === this.props.limit}
                    >
                      <PaginationLink
                        onClick={() => this.props.setLimit(limit)}
                      >
                        {limit}
                      </PaginationLink>
                    </PaginationItem>
                  )
                })}
              </Pagination>
            </div>
            <Pagination size="sm">
              <PaginationItem disabled={!pagination.hasPrevPage}>
                <PaginationLink
                  first
                  onClick={() => this.props.onPageClick(1)}
                />
              </PaginationItem>
              <PaginationItem disabled={!pagination.hasPrevPage}>
                <PaginationLink
                  previous
                  onClick={() => this.props.onPageClick(pagination.page - 1)}
                />
              </PaginationItem>
              {pages}
              <PaginationItem disabled={!pagination.hasNextPage}>
                <PaginationLink
                  next
                  onClick={() => this.props.onPageClick(pagination.page + 1)}
                />
              </PaginationItem>
              <PaginationItem disabled={!pagination.hasNextPage}>
                <PaginationLink
                  last
                  onClick={() => this.props.onPageClick(pagination.totalPages)}
                />
              </PaginationItem>
            </Pagination>
          </div>
        )}
      </>
    )
  }
}

TableView.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      accessor: PropTypes.string,
      render: PropTypes.func,
      sortable: PropTypes.bool,
      textAlign: PropTypes.string,
      width: PropTypes.string,
      isAlphaFeature: PropTypes.bool
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  pagination: PropTypes.object
}

export default TableView
