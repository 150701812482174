export default areas => {
  let result = {
    total: 0,
    types: {}
  }

  if (!areas || !areas.length) {
    return result
  }

  areas.forEach(area => {
    if (area.squareFootage) {
      result.total += area.squareFootage

      if (area.floorType) {
        if (result.types[area.floorType]) {
          result.types[area.floorType] += area.squareFootage
        } else {
          result.types[area.floorType] = area.squareFootage
        }
      }
    }
  })

  return result
}
