import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import qs from 'query-string'

import RouteLogoFull from 'assets/img/route-logo-full.png'
import Header from 'components/layout/Header'
import { createToken } from 'api'

const Screen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding-top: 15vh;
`

const Logo = styled.img`
  width: 300px;
  height: auto;
`

const Text = styled.p`
  padding: 4rem 1rem;
  color: ${({ theme }) => theme.colors.light1};
  text-align: center;
`

const LoginRedirect = ({ }) => {
  const [user, setUser] = useState(null)
  const token = useSelector(state => state.user?.token)
  const profile = useSelector(state => state.user?.profile)
  const employment = useSelector(state => state.user?.employment)
  const currentEmployment = useSelector(state => state.user?.currentEmployment)
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    const getUser = async (access_token) => {
      try {
        const authUser = await axios.get(`${process.env.REACT_APP_AUTH0_DOMAIN}/userinfo`, {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        })

        if (!authUser.data) {
          return res.status(401).json({ message: 'Unauthorized' })
        }

        setUser(authUser.data)
      } catch (e) {
        console.log(e.message);
      }
    };
    if (
      window.location.search?.includes('access_token=')
    ) {
      const query = qs.parse(window.location.search)
      const access_token = query.access_token
      window.localStorage.setItem('AuthToken', access_token)
      getUser(access_token);
    }
  }, []);

  useEffect(() => {
    const auth0LoggedIn = async () => {
      await dispatch({
        type: 'USER_LOGIN_SUCCESS',
        payload: {
          data: {
            user
          }
        }
      })

      const res = await dispatch(createToken(user))

      setIsLoading(false)
    }

    if (user) {
      auth0LoggedIn()
    }
  }, [user])

  if (profile && token && currentEmployment && !isLoading) {
    return <Redirect to="/dashboard/" />
  }

  if (profile && !currentEmployment && !isLoading) {
    window.location.href = `${process.env.REACT_APP_LOGIN_DOMAIN}/billing`
  }

  return (
    <Screen>
      <Header hideSearch />
      <Logo src={RouteLogoFull} />
      <Text>
        Loading Route Bid data, please hold...
      </Text>
    </Screen>
  )
}

export default LoginRedirect