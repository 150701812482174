import React, { Component } from 'react'
import { connect } from 'react-redux'
import Text from './knobs/Text'
import { Button, Card, CardBody } from 'reactstrap'
import DropdownList from 'react-widgets/lib/DropdownList'
import styled from 'styled-components'
import { TaskFrequencies } from 'data'
import { CloseButton } from 'components/lib/Button'
import { IoIosReorder } from 'react-icons/io'
import { FiArrowDown, FiArrowUp } from 'react-icons/fi'
import { axiosClient } from 'store'
import { connectConfirmation } from 'components/confirm'
import { toast } from 'react-toastify'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import _ from 'lodash'
import TaskTemplates from 'data/TaskTemplates.json'
import VirucideTemplate from 'data/VirucideSoWTemplate.json'
import { getServiceTemplates, getTemplates } from 'api'
import Label from './knobs/Label'
import Boolean from './knobs/Boolean'
import Number from './knobs/Number'

const InputAndFrequency = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const StyledDirectionButton = styled(Button)`
  &:hover svg {
    color: blue !important;
  }
`

const DirectionButton = props => {
  return (
    <StyledDirectionButton close {...props}>
      <span aria-hidden>{props.children}</span>
    </StyledDirectionButton>
  )
}

const getItemStyle = (isDragging, draggableStyle, index) => ({
  // some basic styles to make the items look a bit nicer

  //display: isDragging ? 'table' : null,

  // styles we need to apply on draggables
  ...draggableStyle,

  backgroundColor: isDragging ? 'white' : null,
  boxShadow: isDragging ? '0 4px 8px rgba(0, 0, 0, 0.1)' : null,
  border: isDragging ? '1px solid #ccc' : null,
  borderTop: !isDragging && index === 0 ? null : '1px solid #ccc'
})

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const pristineTaskSection = [
  {
    name: 'Task Section',
    tasks: [{ name: 'Add tasks', frequency: 'Daily' }]
  }
]

class ScopeOfWork extends Component {
  state = {}

  constructor(props) {
    super()
    this.inputRefs = {}
  }

  async componentDidMount() {
    await this.props.getServiceTemplates()
    await this.props.getTemplates()
    await this.fetchCustomElements()
  }

  fetchCustomElements = async () => {
    try {
      const result = await axiosClient.get('/api/proposals/customElements')

      if (result.data && result.data.data) {
        const filteredElements = result.data.data.filter(
          el => el.type === 'scope_of_work'
        )
        const customScopes = filteredElements.map(el => {
          return {
            id: el._id,
            name: el.name,
            taskSections: el.data.taskSections
          }
        })
        this.setState({
          customScopes: customScopes
        })
      }
    } catch (ex) {
      console.error('Error fetching custom elements.', ex)
    }
  }

  archiveCustomElement = async id => {
    if (!id) {
      return
    }
    try {
      await axiosClient.patch(`/api/proposals/customElements/archive/${id}`)

      await this.fetchCustomElements()
      this.setState({ selectedService: null })
    } catch (ex) {
      console.error('Error archiving custom element.', ex)
    }
  }

  _getBindableTasks = areas => {
    let areaTypes
    let areaGroups
    let results = []

    areaTypes = _.uniq(areas.map(area => area.type))
    areaGroups = _.groupBy(areas, 'type')

    areaTypes.forEach(areaType => {
      if (TaskTemplates[areaType]) {
        const data = TaskTemplates[areaType]
        let name = data.sectionName
        if (areaGroups[areaType] && areaGroups[areaType].length === 1) {
          name = areaGroups[areaType][0].name
        }

        results.push({
          name,
          tasks: data.tasks
        })
      }
    })

    return results
  }

  _applyJanitorialTemplate = async () => {
    const { proposal, element, onChange, locationTemplates } = this.props
    let taskSections = (element.data && element.data.taskSections) || []
    const taskSectionsPristine = _.isEqual(taskSections, pristineTaskSection)

    // 1st preference: Job (walkthrough) areas.
    // 2nd preference: Stored areas in the location.
    let areas = proposal.job
      ? proposal.job.areas
      : proposal.location
      ? proposal.location.areas
      : []
    if (
      (!areas || !areas.length) &&
      locationTemplates &&
      locationTemplates.length
    ) {
      // 3rd preference: Apply template of location.
      let location = _.find(locationTemplates, { name: proposal.location.type })
      areas = location && location.areas
    }

    if (areas && areas.length && areas.length > 0) {
      let newTasks = this._getBindableTasks(areas)

      if (newTasks && newTasks.length) {
        if (taskSectionsPristine) {
          taskSections = newTasks
        } else {
          taskSections = taskSections.concat(newTasks)
        }
      }

      onChange({
        ...element.data,
        taskSections
      })
    } else {
      toast(
        'The janitorial template needs areas or an applicable location type.'
      )
    }
  }

  _applyServiceTemplate = async service => {
    const { element, onChange } = this.props

    if (!service) {
      return
    }

    const newSection = {
      name: service.name,
      tasks: service.tasks
    }
    let taskSections = (element.data && element.data.taskSections) || []
    const taskSectionsPristine = _.isEqual(taskSections, pristineTaskSection)

    if (taskSectionsPristine) {
      taskSections = [newSection]
    } else {
      taskSections = taskSections.concat(newSection)
    }

    onChange({
      ...element.data,
      taskSections
    })
  }

  _applyCustomScope = async scope => {
    const { element, onChange } = this.props

    if (!scope) {
      return
    }

    const newSections = scope.taskSections
    let taskSections = (element.data && element.data.taskSections) || []
    const taskSectionsPristine = _.isEqual(taskSections, pristineTaskSection)

    if (taskSectionsPristine) {
      taskSections = newSections
    } else {
      taskSections = taskSections.concat(newSections)
    }

    onChange({
      ...element.data,
      taskSections
    })
  }

  _applyVirucideTemplate = () => {
    const { element, onChange } = this.props

    const newSections = VirucideTemplate.map(section => {
      return {
        name: section.sectionName,
        tasks: section.tasks
      }
    })
    let taskSections = (element.data && element.data.taskSections) || []
    const taskSectionsPristine = _.isEqual(taskSections, pristineTaskSection)

    if (taskSectionsPristine) {
      taskSections = newSections
    } else {
      taskSections = taskSections.concat(newSections)
    }

    onChange({
      ...element.data,
      taskSections
    })
  }

  getRenderItem = (tasks, sectionIndex) => (provided, snapshot, rubric) => {
    const { element, onChange } = this.props
    const task = tasks[rubric.source.index]

    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        style={getItemStyle(
          snapshot.isDragging,
          provided.draggableProps.style,
          rubric.source.index,
          tasks.length
        )}
        key={`rendered-task-${rubric.source.index}`}
      >
        <InputAndFrequency>
          <div {...provided.dragHandleProps}>
            <IoIosReorder size={24} />
          </div>
          <input
            ref={ref =>
              (this.inputRefs[`${sectionIndex}${rubric.source.index}`] = ref)
            }
            style={{
              width: '100%',
              minWidth: 170,
              borderStyle: 'none',
              outline: 'none'
            }}
            placeholder="Enter task"
            onKeyPress={async evt => {
              if (evt.key === 'Enter') {
                let newData = {
                  ...element.data
                }
                newData.taskSections[sectionIndex].tasks.push({
                  name: '',
                  frequency: ''
                })
                await onChange(newData)

                this.inputRefs[`${sectionIndex}${tasks.length - 1}`].focus()
              }
            }}
            value={task && task.name ? task.name : ''}
            onChange={event => {
              let newData = {
                ...element.data
              }
              if (!newData.taskSections[sectionIndex].tasks) {
                newData.taskSections[sectionIndex].tasks = [{}]
              }
              newData.taskSections[sectionIndex].tasks[
                rubric.source.index
              ].name = event.target.value
              return onChange(newData)
            }}
            onFocus={() => {
              const input = this.inputRefs[
                `${sectionIndex}${rubric.source.index}`
              ]
              let endPosition =
                (input && input.value && input.value.length) || 0
              return setTimeout(
                () => input.setSelectionRange(endPosition, endPosition),
                10
              )
            }}
          />

          {!!TaskFrequencies && (
            <DropdownList
              style={{ width: 180 }}
              value={task && task.frequency ? task.frequency : ''}
              data={TaskFrequencies}
              onChange={value => {
                let newData = {
                  ...element.data
                }
                if (!newData.taskSections[sectionIndex].tasks) {
                  newData.taskSections[sectionIndex].tasks = [{}]
                }
                newData.taskSections[sectionIndex].tasks[
                  rubric.source.index
                ].frequency = value
                return onChange(newData)
              }}
            />
          )}
          <CloseButton
            onClick={() => {
              let newData = {
                ...element.data
              }
              let newTasks = [...tasks]
              newTasks.splice(rubric.source.index, 1)
              newData.taskSections[sectionIndex].tasks = newTasks
              onChange(newData)
            }}
          />
        </InputAndFrequency>
      </div>
    )
  }

  _renderTasks = sectionIndex => {
    const { element } = this.props
    let tasks =
      element &&
      element.data &&
      element.data.taskSections[sectionIndex] &&
      element.data.taskSections[sectionIndex].tasks

    return (
      tasks &&
      tasks.length &&
      tasks.length > 0 &&
      tasks.map((task, index) => {
        return (
          <Draggable
            key={`task-${index}`}
            draggableId={`task-draggable-${index}`}
            index={index}
          >
            {this.getRenderItem(tasks, sectionIndex)}
          </Draggable>
        )
      })
    )
  }

  _renderSections = () => {
    const { element, onChange } = this.props
    const sections = element && element.data && element.data.taskSections

    if (!sections || !sections.length) {
      return
    }

    return sections.map((section, index) => (
      <Card key={`task-section-${index}`}>
        <CardBody>
          <Text
            label="Task Section Name"
            field="name"
            data={element.data.taskSections[index]}
            onChange={data => {
              let newData = {
                ...element.data
              }
              newData.taskSections[index] = data
              onChange(newData)
            }}
          />
          {sections[index].tasks && sections[index].tasks.length > 0 && (
            <DragDropContext
              onDragEnd={result => {
                // dropped outside the list
                if (!result.destination) {
                  return
                }
                let newData = { ...element.data }
                let newSection = newData.taskSections[index]

                const newTasks = reorder(
                  sections[index].tasks,
                  result.source.index,
                  result.destination.index
                )
                newSection.tasks = newTasks
                newData.taskSections[index] = newSection

                onChange(newData)
              }}
            >
              <div />
              <Droppable
                droppableId="section-tasks-droppable"
                renderClone={this.getRenderItem(sections[index].tasks, index)}
              >
                {(provided, snapshot) => (
                  <div
                    style={{ border: '1px solid #ccc' }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {this._renderTasks(index)}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
          <Button
            style={{ marginTop: 8 }}
            size="sm"
            color="secondary"
            outline
            onClick={() => {
              let newData = {
                ...element.data
              }
              if (!newData.taskSections[index].tasks) {
                newData.taskSections[index].tasks = []
              }
              newData.taskSections[index].tasks.push({
                name: '',
                frequency: ''
              })
              onChange(newData)
            }}
          >
            Add Task
          </Button>
          <Number
            min={0}
            max={200}
            field="spacer"
            label="Spacer after section"
            data={element.data.taskSections[index]}
            onChange={data => {
              let newData = {
                ...element.data
              }
              newData.taskSections[index] = data
              onChange(newData)
            }}
          />
          <div style={{ position: 'absolute', top: 4, right: 8 }}>
            <CloseButton
              onClick={() => {
                let newData = {
                  ...element.data
                }
                let newSections = [...sections]
                newSections.splice(index, 1)
                newData.taskSections = newSections
                onChange(newData)
              }}
            />
            {index < sections.length - 1 && (
              <DirectionButton onClick={() => this._moveSectionDown(index)}>
                <FiArrowDown />
              </DirectionButton>
            )}
            {index > 0 && (
              <DirectionButton onClick={() => this._moveSectionUp(index)}>
                <FiArrowUp />
              </DirectionButton>
            )}
          </div>
        </CardBody>
      </Card>
    ))
  }

  addButton = () => {
    const { element, onChange } = this.props
    return (
      <Button
        size="sm"
        onClick={() => {
          let newData = {
            ...element.data
          }
          if (!newData.taskSections) {
            newData.taskSections = []
          }
          newData.taskSections.push({ name: 'Task Section' })
          return onChange(newData)
        }}
      >
        Add Task Section
      </Button>
    )
  }

  _moveSectionUp = oldIndex => {
    const { element, onChange } = this.props
    const sections = element && element.data && element.data.taskSections
    const newSections = reorder(sections, oldIndex, oldIndex - 1)

    return onChange({
      ...element.data,
      taskSections: newSections
    })
  }

  _moveSectionDown = oldIndex => {
    const { element, onChange } = this.props
    const sections = element && element.data && element.data.taskSections
    const newSections = reorder(sections, oldIndex, oldIndex + 1)

    return onChange({
      ...element.data,
      taskSections: newSections
    })
  }

  render() {
    const { element, onChange, services } = this.props
    const { selectedService, customScopes } = this.state

    let servicesWithTasks = [
      {
        name: 'Route Janitorial Template',
        addFunc: this._applyJanitorialTemplate
      },
      {
        name: 'Route Virucide Template',
        addFunc: this._applyVirucideTemplate
      }
    ]

    servicesWithTasks.concat(
      _.filter(services, service => service.tasks && service.tasks.length)
    )

    if (customScopes) {
      servicesWithTasks.push(...customScopes)
    }

    return (
      <>
        <Text
          placeholder="Element Name"
          field="name"
          data={element.data}
          onChange={onChange}
        />
        <Boolean
          label="Show Heading"
          data={element.data}
          field="showHeading"
          onChange={onChange}
        />
        {element.data && element.data.showHeading && (
          <Text data={element.data} field="headingText" onChange={onChange} />
        )}
        <Number
          min={6}
          max={20}
          field="textSize"
          label="Text size"
          data={element.data}
          onChange={onChange}
        />
        {servicesWithTasks && (
          <div style={{ marginBottom: 16 }}>
            <Label text="Apply task templates" />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <DropdownList
                textField="name"
                data={servicesWithTasks}
                style={{ width: '60%', marginRight: 12 }}
                onChange={value => {
                  this.setState({
                    selectedService: value
                  })
                }}
              />
              <Button
                style={{ height: 36, marginRight: 4 }}
                onClick={() => {
                  if (!selectedService) {
                    return toast('Please select a template.')
                  }
                  if (selectedService.addFunc) {
                    selectedService.addFunc()
                  } else if (selectedService.taskSections) {
                    this._applyCustomScope(selectedService)
                  } else {
                    this._applyServiceTemplate(selectedService)
                  }
                }}
              >
                Apply
              </Button>
              {selectedService && selectedService.taskSections ? (
                <Button
                  color="danger"
                  style={{ height: 36 }}
                  onClick={() => {
                    this.props.confirm(
                      'Archive Custom Scope of Work',
                      'Are you sure you want to archive this custom scope of work?',
                      () => this.archiveCustomElement(selectedService.id)
                    )
                  }}
                >
                  Delete
                </Button>
              ) : null}
            </div>
          </div>
        )}
        <div style={{ marginBottom: 8 }}>{this.addButton()}</div>
        {this._renderSections()}
        {element &&
          element.data &&
          element.data.taskSections.length > 2 &&
          this.addButton()}
      </>
    )
  }
}

const mapStateToProps = state => ({
  services: state.library.services,
  locationTemplates: state.templates.templates
})

export default connectConfirmation(
  connect(
    mapStateToProps,
    {
      getServiceTemplates,
      getTemplates
    },
    null,
    {
      forwardRef: true
    }
  )(ScopeOfWork)
)
